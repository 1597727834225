<template>
  <v-row>
    <v-col lg="12" sm="12">
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        no-data-text="No se han cargado datos aun"
        no-results-text="No hay resultados"
        :footer-props="{
          'items-per-page-text': 'Registros por fila',
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Buscar"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.status="props">
          <v-chip v-if="props.item.status === true" color="green" dark>
            <v-icon>mdi-check</v-icon>
          </v-chip>
          <v-chip v-else color="warning" dark>
            <v-icon>mdi-close</v-icon>
          </v-chip>
        </template>
        <template v-slot:item.opciones="props">
          <tr>
            <td style="padding-right: 20px">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    dark
                    color="warning"
                    @click="editar(props.item.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    dark
                    color="red"
                    @click="borrar(props.item.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import { API_URL } from '@/common/config';

const slugify = require('slugify');

const _ = require('lodash');

const axios = require('axios');

export default {
  props: ['model', 'datos', 'hiddenColumns', 'translatedColumns'],
  data() {
    return {
      loaded: false,
      search: '',
      data: this.datos,
      undata: {
        name: 'Sin Datos',
        value: 'No hay datos que mostrar',
      },
      headers: [],
      header: {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      },
    };
  },
  computed: {},
  methods: {
    setColumns() {
      const aux = [];
      if (this.datos.length > 0) {
        this.headers = Object.keys(this.datos[0]);
        this.hideColumns();
        this.headers.forEach((element) => {
          aux.push({
            text: this.translateColumns(element),
            value: slugify(element),
          });
        });
      }
      aux.push({ text: 'Opciones', value: 'opciones' });
      this.headers = aux;
    },
    setData() {
      if (this.datos) {
        //  Aca se editan los campos createdAd y updateAd.-
        this.datos.forEach((dato) => {
          //  Esta función tranforma la fecha unix a fecha normal.-
          function transformDate(unixDate) {
            const date = new Date(unixDate);
            return `${date.getDay()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
          }
          const newDato = dato;

          if (this.model === 'tipoParametros') {
            newDato.tipo_servicio_id = dato.tipo_servicio_id.nombre;
          }
          if (this.model === 'unidadGestion') {
            newDato.sectorProductivo = dato.sectorProductivo.nombre;
          }
          if (this.model === 'servicio') {
            newDato.tipo_servicio_id = dato.tipo_servicio_id.nombre;
            newDato.fideicomiso_id = dato.fideicomiso_id.id;
          }
          if (this.model === 'cfgNotificacion') {
            newDato.cfgNotificacionMsg_id =
              dato.cfgNotificacionMsg_id.descripcion;
            newDato.rol_id = dato.rol_id.nombre;
          }
          newDato.createdAt = transformDate(dato.createdAt);
          newDato.updatedAt = transformDate(dato.updatedAt);
          return newDato;
        });
        this.data = this.datos;
      }
    },
    hideColumns() {
      this.headers = _.difference(this.headers, this.hiddenColumns);
    },
    translateColumns(column) {
      let res = column;
      const idx = _.findIndex(this.translatedColumns, ['campo', column]);
      if (parseInt(idx, 10) > -1) {
        res = this.translatedColumns[idx].trad;
      }
      return res;
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    async borrar(id) {
      let url = `${API_URL}api/${this.model}/borrar/${id}`;
      if (this.model === 'documentos') {
        url = `${API_URL}api/documentoMaestros/borrar/${id}`;
      }
      this.$swal({
        title: '¿Esta seguro?',
        text: 'Se borrará el registro',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, Cancelar',
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          axios.get(url, this.header).then((response) => {
            if (response.data.status === 'success') {
              // this.$swal('Borrado', 'Se ha eliminado el registro', 'success');
              this.$router.go(0);
            } else {
              this.noresult = true;
              this.$swal(
                'Cancelado',
                'No se ha realizado la operación',
                'info'
              );
            }
          });
        } else {
          // this.$swal('Cancelado', 'No se ha realizado la operación', 'info');
        }
      });
    },
    editar(id) {
      const url = `/editar/${this.model}/${id}`;
      this.$router.push(url);
    },
  },
  created() {
    this.loaded = true;
    this.setData();
    if (this.datos !== undefined) {
      this.setColumns();
    }
  },
};
</script>
<style>
.swal2-title,
.swal2-content,
.swal2-styled {
  font-family: Roboto, sans-serif !important;
}
</style>
