var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-breadcrumbs',{attrs:{"items":_vm.bread,"large":""}})],1),_c('v-row',[_c('v-col',[(_vm.titulo === 'Unidad Gestion')?_c('div',[_c('h1',[_vm._v("Unidad de gestión")])]):(_vm.titulo === 'Configuracion Mensaje Notificacion')?_c('div',[_c('h1',[_vm._v("Configuración Mensaje Notificación")])]):(_vm.titulo === 'Configuracion Notificacion')?_c('div',[_c('h1',[_vm._v("Configuración Notificaciones")])]):_c('div',[_c('h1',[_vm._v(_vm._s(_vm.titulo))])]),_c('br'),_c('v-btn',{attrs:{"color":"primary","to":{ name: 'Nuevo', params: { model: this.$route.params.model } }}},[_vm._v(" Agregar Nuevo ")]),_c('br'),(_vm.loaded)?_c('Tabla',{attrs:{"model":this.$route.params.model,"hiddenColumns":[
          'id',
          'imagenURL',
          'password',
          'emailProofToken',
          'emailProofTokenExpiresAt',
          'encryptedPassword',
          'userCreated_id',
          'bases',
          'descripcion',
          'telefono',
          'destino',
          'options' ],"datos":_vm.data,"translatedColumns":[
          { campo: 'createdAt', trad: 'Creado' },
          { campo: 'updatedAt', trad: 'Actualizado' },
          { campo: 'rol_id', trad: 'Rol' },
          { campo: 'emailStatus', trad: 'Correo confirmado' },
          { campo: 'email', trad: 'Correo Electronico' },
          { campo: 'userCreated_id', trad: 'Creado por' },
          { campo: 'lineaCredito_id', trad: 'Operatoria' },
          { campo: 'tipo_parametro_id', trad: 'Parametro' },
          { campo: 'nombre', trad: 'Nombre' },
          { campo: 'vencimiento', trad: 'Vencimiento' },
          { campo: 'fideicomiso_id', trad: 'Unidad de Gestión' },
          { campo: 'sectorProductivo', trad: 'Sector Productivo' },
          { campo: 'tipo_servicio_id', trad: 'Tipo de operatoria' },
          { campo: 'cfgNotificacionMsg_id', trad: 'Mensaje' },
          {
            campo: 'status', // Assuming your data has a 'status' property (boolean)
            trad: 'Activa',
          } ]}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }