var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"lg":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"name","search":_vm.search,"no-data-text":"No se han cargado datos aun","no-results-text":"No hay resultados","footer-props":{
        'items-per-page-text': 'Registros por fila',
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.status",fn:function(props){return [(props.item.status === true)?_c('v-chip',{attrs:{"color":"green","dark":""}},[_c('v-icon',[_vm._v("mdi-check")])],1):_c('v-chip',{attrs:{"color":"warning","dark":""}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}},{key:"item.opciones",fn:function(props){return [_c('tr',[_c('td',{staticStyle:{"padding-right":"20px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","dark":"","color":"warning"},on:{"click":function($event){return _vm.editar(props.item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","dark":"","color":"red"},on:{"click":function($event){return _vm.borrar(props.item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }